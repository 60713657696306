@import '../../theme/colours.scss';

.container {
    margin: 20px 0 20px 0;
}

.title {
    margin-top: 20px;
    margin-bottom: 20px;
}

.jsoncontainer {
    border: 2px solid $colour-purple;
    padding: 20px;
    box-sizing: border-box;
    background-color: $colour-purple-faded;
    height: 500px;
    overflow: scroll;
    border-radius: 10px;
}

.success {
    margin-top: 20px;
    background-color: #1fbe21;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    color: white;
}

.error {
    margin-top: 20px;
    background-color: red;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    color: white;
}