.container {
    margin: 20px 0 20px 0;
}

.title {
    margin: 20px 0 20px 0;
}

.success {
    margin-top: 20px;
    background-color: #1fbe21;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    color: white;
}

.error {
    margin-top: 20px;
    background-color: red;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    color: white;
}